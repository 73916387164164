<template>
  <div class="container">
    <filter-view @resetFilters="resetFilters" ref="filtersView">
      <template v-slot:filters>
        <div class="w-100 float-left mb-3">
          <label class="font-size-sm color-secondary">
            {{ $t("categories.choose_category") }}
          </label>
          <Select2
            v-model="selected_category"
            :options="select2_options"
            :settings="{ placeholder: category_search_placeholder }"
          />
        </div>
        <!-- /.select2holder -->

        <products-section-filters
          :defaults="section_filters"
          @saved="savedFilters"
          ref="filters"
        />
      </template>
      <template v-slot:body>
        <!-- PRODUCT-SECTIONS Skeleton Loader -->
        <div v-if="!productSectionLoaded">
          <div class="row mb-5">
            <div
              class="col-6 col-lg-4 col-xl-3 skeleton mb-3"
              v-for="index in 12"
              :key="index"
            >
              <div class="ratio ratio-4_3 bg-gray"></div>
              <div class="line skeleton_loader"></div>
              <div class="line skeleton_loader w-50"></div>
              <div class="line skeleton_loader dark w-30 thick"></div>
            </div>
          </div>
        </div>
        <div class="row mb-5" v-if="productSectionLoaded">
          <div class="col-12 mb-2">
            <div class="font-size-lg font-weight-bold">
              {{ $t("global.search_results") }}
            </div>
            <div class="font-size-sm color-secondary">
             {{ $t("global.search_results_found", {'nr' :total }) }}
            </div>
          </div>
          <!-- /.col-12 -->
          <product-card
            class="col-6 col-lg-4 col-xl-3"
            v-for="product in products"
            :product="product"
            :key="product.id"
          />
        </div>

        <loading :loading="!loading_ready" />
      </template>
    </filter-view>
  </div>
</template>

<script>
//TODO: CHECK IF NO PRODUCTS FOUND
import { mapGetters, mapActions } from "vuex";
import { ref } from "vue";
import FilterView from "@/components/FilterView.vue";
import Loading from "@/components/Loading.vue";
import ProductCard from "@/components/ProductCard.vue";
import ProductsSectionFilters from "@/components/ProductsSectionFilters.vue";
import Select2 from "vue3-select2-component";
import prepareGuestAddressParams from "@/includes/prepareGuestAdderssParams";
import {useMeta} from "vue-meta";
import {useRoute} from "vue-router";

export default {
  components: {
    FilterView,
    ProductsSectionFilters,
    ProductCard,
    Loading,
    Select2,
  },
  name: "Search",
  setup() {
    const filters = ref(null);
    const filtersView = ref(null);

    const route = useRoute();
    useMeta({
      title: route.params.query,
    })

    return {
      filters,
      filtersView,
    };
  },
  data() {
    return {
      category_search_placeholder: this.$t("global.search") + "...",
      selected_category: "-1",
      select2_options: null,
      current_page: 1,
      last_page: 1,
      total: 0,
      pagination: 16,
      products: [],
      loading: false,
      productSectionLoaded: false,
      loading_ready: true,
      section_filters: {
        payment_method: "both",
        sort: [],
        radius: 15,
      },
    };
  },
  methods: {
    ...mapActions(["getCategories","setErrors"]),

    async search(form_data = {}, page = 1) {
      form_data["search_term"] = this.query;
      form_data["pagination"] = this.pagination;
      form_data["page"] = page;

      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("searchProductsByFilter");
        window.axios
          .get(url, {
            params: prepareGuestAddressParams(form_data),
          })
          .then(
            (response) => {
              this.current_page = response.data.meta.current_page;
              this.last_page = response.data.meta.last_page;
              this.total = response.data.meta.total;
              resolve(response.data);
            },
            (error) => {
              this.setErrors(error.response.data.errors);
              reject(error.response.data.errors);
            }
          );
      });
    },
    resetFilters() {
      this.selected_category = "-1";
      this.section_filters = {
        payment_method: "both",
        sort: [],
        radius: 15,
      };

      this.formData = this.prepareData(this.section_filters);
    },
    prepareData(data) {
      let formData = JSON.parse(JSON.stringify(data));
      if (formData["sort_by_price"] == undefined) {
        formData["sort_by_price"] = [];
      } else {
        formData["sort_by_price"] = [formData["sort_by_price"]];
      }
      //Prepare category
      formData["shop_type_id"] = null;
      if (this.selected_category != "-1") {
        formData["shop_type_id"] = parseInt(this.selected_category);
      }

      let sort_by = [...formData["sort"], ...formData["sort_by_price"]];
      formData["sort_by"] = sort_by;

      return formData;
    },
    savedFilters(filters) {
      this.productSectionLoaded = false;
      this.formData = this.prepareData(filters);
      //GET PRODUCTS
      this.search(this.formData).then((response) => {
        this.productSectionLoaded = true;
        this.products = response.data;
      });

      this.filtersView.closeFilters();
    },
    loadMore() {
      if (this.loading_ready === true) {
        if (
          this.current_page < this.last_page &&
          this.productSectionLoaded === true
        ) {
          if (
            window.innerHeight + window.scrollY >=
            document.body.scrollHeight
          ) {
            //DISPLAY LOADING BAR and disable scroll bottom event
            this.loading_ready = false;
            //LOAD MORE PRODUCTS
            this.search(this.formData, this.current_page + 1).then(
              (response) => {
                //HIDE LOADING BAR and enable scroll bottom event
                this.loading_ready = true;
                this.products = [...this.products, ...response.data];
              }
            );
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters(["categories"]),
    categories_count: function () {
      return Object.keys(this.categories).length;
    },
    query: function () {
      return this.$route.params.query;
    },
  },
  watch: {
    categories: function () {
      let prepare_categories = [];
      prepare_categories.push({
        id: -1,
        text: this.$t("global.all"),
      });

      for (const category of this.categories) {
        prepare_categories.push({
          id: category.id,
          text: category.name,
        });
      }

      this.select2_options = prepare_categories;
    },
    query: function () {
      //Reset Placeholders
      this.productSectionLoaded = false;
      this.search().then((response) => {
        this.products = response.data;
        this.productSectionLoaded = true;
      });
    },
  },
  created() {
    this.search().then((response) => {
      this.products = response.data;
      this.productSectionLoaded = true;
    });
    //Check if categories state is empty
    if (this.categories_count == 0) {
      this.getCategories();
    }
  },
  mounted() {
    window.onscroll = () => {
      this.loadMore();
    };
  },
};
</script>

<style>
</style>
